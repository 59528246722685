import { createRouter, createWebHistory } from 'vue-router'
import { useUserStore } from '@/stores/userStore.ts'

import cookies from '@/helpers/cookies'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      meta: {
        loginRequired: true,
        redirectToProfile: true,
      },
      component: () => import('@/views/Home/index.vue')
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      meta: {
        loginRequired: true,
      },
      component: () => import('@/views/Dashboard/index.vue')
    },
    {
      path: '/search/:field?/:role?',
      name: 'search',
      meta: {
        loginRequired: true,
        hideWizard: true,
      },
      component: () => import('@/views/Search/index.vue')
    },
    {
      path: '/profile',
      name: 'profile',
      meta: {
        loginRequired: true,
      },
      component: () => import('@/views/Profile/index.vue')
    },
    {
      path: '/roadmap',
      name: 'roadmap',
      meta: {
        loginRequired: false,
        hideBackDrop: true,
      },
      component: () => import('@/views/Roadmap/index.vue')
    },
    {
      path: '/u/:id',
      name: 'User',
      meta: {
        hideWizard: true,
        hideBackDrop: true,
      },
      component: () => import('@/views/PublicProfile/index.vue')
    },
    {
      path: '/verify',
      name: 'verify-email',
      meta: {
        loginRequired: true,
        hideBackDrop: true,
        headerStyle: 'hidden',

        hideModals: true,
        hideWizard: true,
      },
      component: () => import('@/views/Auth/VerifyEmail/index.vue')
    },
  ]
})

router.onError((error, to) => {
  if (error.message.includes('Failed to fetch dynamically imported module') || error.message.includes("Importing a module script failed")) {
    window.location = to.fullPath as unknown as Location;
  }
})

router.beforeEach(async (to, from) => {
  let isAuthenticated = useUserStore().authToken != null
  const authToken = cookies.getCookie(import.meta.env.VITE_COOKIE_NAME)

  if (!isAuthenticated && authToken && ![null, '', undefined].includes(authToken)) {
    try {
      isAuthenticated = await useUserStore().logInWithToken()
    } catch (error) {
      // console.log(error)
    }
  }

  if (!isAuthenticated && to.meta.loginRequired) {
    useUserStore().triggerLoginModal()
  }

  // let returnData = true

  // // if requiresAuth && !isAuthenticated or !requiresAuth && isAuthenticated
  // if ((to.meta.requiresAuth && !isAuthenticated) || (!to.meta.requiresAuth && isAuthenticated)) {
  //   if (to.meta.authRedirect && to.meta.authRedirect !== to.name) {
  //     returnData = { name: to.meta.authRedirect }

  //   }
  // }

  // return returnData
})

router.afterEach(() => {
  setTimeout(() => {
    window.scrollTo(0, 0)
  }, 250)
})

export default router
