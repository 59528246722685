import { ref, computed } from 'vue'
import { defineStore } from 'pinia'

import { type Field, type Role } from '@/types/Talents.ts'

import axios from 'axios'

export const useTalentStore = defineStore('talent', () => {

  const fieldFetchLoading = ref<boolean>(true)
  const fields = ref<Field[]>([])
  const roles = ref<Role[]>([])

  const searchResults = ref({})

  // getters
  const getFields = computed(() => fields.value)
  const getFeaturedFields = computed(() => fields.value.filter(field => field.featured))
  const getFieldOptions = computed(() => getFeaturedFields.value.map((field) => ({ value: field.slug, label: field.name })))

  const getRoles = computed(() => roles.value)
  const getFeaturedRoles = computed(() => roles.value.filter(role => role.featured))
  
  const getRolesByField = computed(() => (fieldId: string, featuredOnly: boolean = false) => {
    const field = fields.value.find(f => f.slug === fieldId);
    if (!field) return [];
    
    return roles.value.filter(role => field.roles.includes(role.slug) && (!featuredOnly || role.featured));
  });

  const getRoleName = computed(() => (roleId: string) => {
    const role = roles.value.find(r => r.slug === roleId);
    return role ? role.name : 'xx';
  });

  // mutations
  const addFields = (newFields: Field[]) => {
    if (fields.value.length === 0) {
      fields.value = newFields.map((field) => {
        field.expanded = false
        return field
      })

    } else {
      newFields.forEach(newField => {
        

        const existingFieldIndex = fields.value.findIndex(field => field.uuid === newField.uuid)

        if (existingFieldIndex > -1) {
          fields.value[existingFieldIndex].name = newField.name
          fields.value[existingFieldIndex].roles = newField.roles

        } else {
          newField.expanded = false
          fields.value.push(newField)

        }
      })

    }
  }
  const addRoles = (newRoles: Role[]) => {
    if (roles.value.length === 0) {
      roles.value = newRoles

    } else {
      newRoles.forEach(newRole => {
        const existingRoleIndex = roles.value.findIndex(roles => roles.uuid === newRole.uuid)

        if (existingRoleIndex > -1) {
          roles.value[existingRoleIndex].name = newRole.name

        }
      })

    }
  }

  const fetchTalentTreeData = () => {
    return new Promise(function (resolve, reject) {
      if(fields.value.length > 0 && roles.value.length > 0) {
        resolve(true)
        return
      }

      fieldFetchLoading.value = true

      axios({
        method: 'get',
        url: `/v0/fields`,
      }).then(function (response) {
        const responseData = response.data

        if (responseData.fields) {
          addFields(responseData.fields)
        }

        if (responseData.work_roles) {
          addRoles(responseData.work_roles)
        }

        resolve(true)
      }).catch(function (error) {
        const errorResponse = error.formatedMessage ?? "We ran into an error. Please try again."
        reject(errorResponse)
      }).finally(() => {
        fieldFetchLoading.value = false
      })
    })
  }

  const fetchTalentByRole = (roleId: string, freshLoad: boolean = false) => {
    return new Promise(function (resolve, reject) {
      const startTime = Date.now()

      if (!freshLoad && searchResults.value[roleId]) {
        setTimeout(() => {
          resolve(searchResults.value[roleId])
        }, Math.max(0, 400 - (Date.now() - startTime)))

      } else {

        axios({
          method: 'post',
          url: `/v0/search/role/${roleId}`,
        }).then(function (response) {
          const newResults = response.data.results

          searchResults.value[roleId] = newResults

          setTimeout(() => {
            resolve(newResults)
          }, Math.max(0, 1000 - (Date.now() - startTime)))
  
        }).catch(function (error) {
          const errorResponse = error.formatedMessage ?? "We ran into an error. Please try again."
          reject(errorResponse)

        })
      }
    })
  }



  const toggleExpanded = (id: string) => {
    const field = fields.value.find(field => field.slug === id)
    if (field) {
      field.expanded = !field.expanded
    }
  }
  
  return {
    // state
    fieldFetchLoading,

    //getters
    getFields,
    getFieldOptions,
    getFeaturedFields,
    getRoles,
    getFeaturedRoles,
    getRolesByField,
    getRoleName,
    // mutations
    fetchTalentByRole,
    fetchTalentTreeData,
    toggleExpanded,
  }
})
