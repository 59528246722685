import { ref, computed, watch } from 'vue'
import { defineStore } from 'pinia'
import { useRouter } from 'vue-router'

import axios from 'axios'

export const useWizardStore = defineStore('wizard', () => {
  const router = useRouter()

  const wizardType = ref(null)

  const editSteps = ref([])
  const engineeringSteps = [
    {
      slug: 'first-last-name',
      complete: false,
    },
    {
      slug: 'avatar',
      complete: false,
    },
    {
      slug: 'human-type',
      complete: false,
    },
    {
      slug: 'experience',
      complete: false,
    },
    {
      slug: 'primary-role',
      complete: false,
    },
    {
      slug: 'rates-primary-role',
      complete: false,
    },
    {
      slug: 'rates-secondary-role',
      complete: false,
    },
    {
      slug: 'programming',
      complete: false,
    },
    {
      slug: 'frameworks',
      complete: false,
    },
    {
      slug: 'software',
      complete: false,
    },
    {
      slug: 'work-type',
      complete: false,
    },
    {
      slug: 'availability',
      complete: false,
    },
    {
      slug: 'work-arrangement',
      complete: false,
    },
    {
      slug: 'client-sectors',
      complete: false,
    },
    {
      slug: 'client-experience',
      complete: false,
    },
    {
      slug: 'qualifications',
      complete: false,
    },
    {
      slug: 'skills',
      complete: false,
    },
    {
      slug: 'links',
      complete: false,
    },
    {
      slug: 'about',
      complete: false,
    },
  ]
  const defaultSteps = [
    {
      slug: 'first-last-name',
      complete: false,
    },
    {
      slug: 'avatar',
      complete: false,
    },
    {
      slug: 'human-type',
      complete: false,
    },
    {
      slug: 'experience',
      complete: false,
    },
    {
      slug: 'primary-role',
      complete: false,
    },
    {
      slug: 'rates-primary-role',
      complete: false,
    },
    {
      slug: 'rates-secondary-role',
      complete: false,
    },
    {
      slug: 'software',
      complete: false,
    },
    {
      slug: 'work-type',
      complete: false,
    },
    {
      slug: 'availability',
      complete: false,
    },
    {
      slug: 'work-arrangement',
      complete: false,
    },
    {
      slug: 'client-sectors',
      complete: false,
    },
    {
      slug: 'client-experience',
      complete: false,
    },
    {
      slug: 'qualifications',
      complete: false,
    },
    {
      slug: 'skills',
      complete: false,
    },
    {
      slug: 'links',
      complete: false,
    },
    {
      slug: 'about',
      complete: false,
    },
  ]

  const signupFlowOrder = ref(defaultSteps)
  
  const currentWizardType = computed(() => wizardType.value)
  const wizardSteps = computed(() => {
    if (wizardType.value == 'edit') {
      return editSteps.value
    } else {
      return signupFlowOrder.value
    }
  })

  const progressStats = computed(() => {
    const currentIndex = currentStep.value?.index
    const total = signupFlowOrder.value.length

    return {
      width: currentIndex ? `${((currentIndex + 1) / total) * 100}%` : '0%',
      current: currentIndex,
      total: total,
    }
  })
  const currentStep = computed(() => {
    let currentStep = wizardSteps.value[currentStepIndex.value]
    currentStep.index = currentStepIndex.value

    return currentStep
  })
  const currentStepIndex = computed(() => wizardSteps.value.findIndex(step => step.complete === false))
  const currentEditSteps = computed(() => editSteps.value.length > 0 ? editSteps.value : false)
  const showWizard = computed(() => !!wizardType.value)

  const toggleWizard = (toShow: string|null = null) => {
    wizardType.value = toShow
  }

  //add step if it doesn't exist
  const setEditSteps = (steps: array) => {
    if (steps.length > 0) {
      editSteps.value = steps.map(step => {
        return {
          slug: step,
          complete: false,
        }
      })

      toggleWizard('edit')
    }
  }

  const addStep = (slug: string, order: number ) => {
    const stepExists = signupFlowOrder.value.find(step => step.slug === slug)
    if (!stepExists) {
      signupFlowOrder.value.splice(order, 0, {
        slug: slug,
        complete: false,
      })
    }
  }

  const removeStep = (slug: string) => {
    const index = signupFlowOrder.value.findIndex(step => step.slug === slug)
    if (index > -1) {
      signupFlowOrder.value.splice(index, 1)
    }
  }

  const markCurrentStepComplete = () => {
    const completeSteps = wizardSteps.value.filter(item => item.complete).length

    if (completeSteps == (wizardSteps.value.length - 1)) {
      if (wizardType.value == 'setup') {
        localStorage.removeItem(import.meta.env.VITE_COMPLETED_STEPS_NAME)
      }
      
      router.push('/profile')
      wizardType.value = null
    } else {
      wizardSteps.value[currentStepIndex.value].complete = true

      if (wizardType.value == 'setup') {
        trackSteps(completeSteps + 1)
      }
    }
  }

  const backStep = () => {
    if (currentStepIndex.value > 0) {
      signupFlowOrder.value[currentStepIndex.value - 1].complete = false
    }
  }

  const getRoleSignupSteps = (roleId: string) => {
    return new Promise(function (resolve, reject) {
      axios({
        method: 'get',
        url: `/v0/work-role/steps/${roleId}`,
      }).then(function (response) {
        signupFlowOrder.value = response.data.steps

        resolve(true)
      }).catch(function (error) {
        const errorResponse = error.formatedMessage ?? "We ran into an error. Please try again."
        reject(errorResponse)
      });
    })
  }

  const setWizardSteps = (track) => {
    if (track == 'engineering') {
      signupFlowOrder.value = engineeringSteps
    } else {
      signupFlowOrder.value = defaultSteps
    }
    loadStepsFromStorage()
  }

  const trackSteps = (step: number) => {
    localStorage.setItem(import.meta.env.VITE_COMPLETED_STEPS_NAME, step)
  }

  const getAboutGPT = (hobby: string, passions: string) => {
    return new Promise(function (resolve, reject) {
      axios({
        method: 'post',
        url: `/v0/gpt/about`,
        data: {
          'hobby': hobby,
          'passions': passions,
        }
      }).then(function (response) {
        
        resolve(response.data.options)

      }).catch(function (error) {

        reject(error.formatedMessage)
      });
    })
  }

  const loadStepsFromStorage = () => {
    // get the current step from local storage
    const completedSteps = localStorage.getItem(import.meta.env.VITE_COMPLETED_STEPS_NAME)
    if (completedSteps && completedSteps > 0) {
      for (let i = 0; i < completedSteps; i++) {
        if (signupFlowOrder.value[i]) {
          signupFlowOrder.value[i].complete = true
        }
      }
    }
  }
  // loadStepsFromStorage()
  
  return {
    //getters
    currentWizardType,
    currentStep,
    progressStats,
    currentStepIndex,
    currentEditSteps,
    showWizard,
    wizardSteps,
    
    // mutations
    addStep,
    removeStep,
    backStep,
    toggleWizard,
    setWizardSteps,
    markCurrentStepComplete,
    getRoleSignupSteps,
    setEditSteps,
    getAboutGPT,
  }
})
