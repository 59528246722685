<script setup lang="ts">
import { ref } from 'vue'

import TextInput from '@/components/form/TextInput.vue';
import PasswordInput from '@/components/form/PasswordInput.vue'
import Button from '@/components/form/Button.vue';

import { triggerEvent } from '@/helpers/general'
import { useVuelidate } from '@vuelidate/core'
import { minLength, email, required, helpers } from '@vuelidate/validators'

import { useUserStore } from '@/stores/userStore.ts'

const emit = defineEmits(['closeModal'])

const loading = ref<boolean>(false)
const error = ref<string|null>(null)

const emailAddress = ref<string>('');
const password = ref<string>('');

const v$ = useVuelidate({
  emailAddress: {
    required: helpers.withMessage('Email is required', required),
    email: helpers.withMessage('Valid email address required.', email)
  },
  password: {
    required: helpers.withMessage('Password is required.', required),
    minLength: helpers.withMessage('Password needs to be atleast 6 characters.', minLength(8)),
  },
}, { emailAddress, password }, { $lazy: true });

const login = async () => {
  v$.value.$touch();
  if (v$.value.$invalid) return;

    loading.value = true;
  try {
    await useUserStore().logIn({
        email: emailAddress.value,
        password: password.value,
        remember: true
    })

    loading.value = false;
    emit('closeModal')

    //reload the window
    window.location.reload()

  } catch (requestError) {
    loading.value = false;
    error.value = requestError

  }
}

const openRegisterModal = () => {
  triggerEvent('openAuthModal', {
    modalToShow: 'register'
  })
}

</script>

<template>
  <div>
    <div class="mb-3">
      <h4 class="h3">Login</h4>
    </div>

    <form @submit.prevent="login" class="d-flex flex-column gap-3">
      <TextInput
        id="email"
        label="Email Address"
        placeholder="you@cooldomain.com"
        v-model="v$.emailAddress.$model"
        :error="(v$.emailAddress.$dirty && v$.emailAddress.$invalid) ? v$.emailAddress.$errors[0].$message : ''" />


      <PasswordInput
        id="password"
        label="Password"
        placeholder="Password"
        v-model="v$.password.$model"
        :isInvalid="(v$.password.$dirty && v$.password.$invalid)"
        :error="(v$.password.$dirty && v$.password.$invalid) ? v$.password.$errors[0].$message : ''" />
      
      <p v-if="error" class="text-danger small">{{ error }}</p>

      <div class="d-flex justify-content-between align-items-end mt-3">
        <Button 
          class="btn-dark" 
          :disabled="loading" 
          :loading="loading">Submit</Button>
      </div>
      <hr>
      <div class="text-center">
        <a href="#" @click.prevent="openRegisterModal()">
          Need an account? <b>Click here to Register</b>
        </a>
      </div>
    </form>

  </div>

</template>
