<script setup lang="ts">
import { ref, computed, onMounted, watch } from 'vue'
import { useRoute } from 'vue-router'
import Avatar from '@/components/Avatar.vue'
import Dropdown from '@/components/Dropdown.vue'
import BannerSearch from '@/components/BannerSearch.vue'
import { useUserStore } from '@/stores/userStore';
import { useTalentStore } from '@/stores/talentStore'

const userInfo = computed(() => useUserStore().userComputed)

const navType = ref('full')
const showAvatarMenu = ref(false)

const closeAvatarMenu = (e: Event) => {
  if (!(e.target as HTMLElement).closest('.dropdownToggleBtn')) {
    showAvatarMenu.value = false
    document.removeEventListener('click', closeAvatarMenu)
  }
}

const toggleAvatarMenu = () => {
  showAvatarMenu.value = !showAvatarMenu.value
  
  //add a listner
  if (showAvatarMenu.value) {
    document.addEventListener('click', closeAvatarMenu)
  } else {
    document.removeEventListener('click', closeAvatarMenu)
  }
}

const logout = async () => {
  await useUserStore().logOut()
}

const isLoggedIn = computed(() => useUserStore().authSet)

onMounted(() => {
  useTalentStore().fetchTalentTreeData()
})

const route = useRoute()
watch(route, (to) => {
  if (to.meta.headerStyle) {
    navType.value = to.meta.headerStyle
  } else {
    navType.value = 'full'
  }
})
</script>

<template>
  <div v-if="navType !== 'hidden'">
    <div class="headerSpacer bg-white"></div>
    <div class="headerWrapper py-3">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="top-navbar d-flex align-items-center">
              <a href="https://secretsource.co.za">
                <div class="position-relative me-3">
                  <img src="../assets/images/ss-logo.png" class="logo" alt="/secretsource">
                  <span class="bataTag rounded-pill">BETA</span>
                </div>
              </a>

              <BannerSearch location="nav" />
            

              <div class="position-relative ms-auto d-flex align-items-center" v-if="navType !== 'simple'">
                <router-link to="/dashboard" class="nav-link px-2 fw-500"><span>Dashboard</span></router-link>
                <router-link to="/profile" class="nav-link px-2 fw-500" v-if="userInfo.profile_type == 'talent'"><span>My&nbsp;Profile</span></router-link>

                <router-link to="/roadmap" class="nav-link px-2 fw-500"><span>Roadmap</span></router-link>
                <a href="https://api.whatsapp.com/send/?phone=27795820675" class="nav-link px-2 fw-500 d-none d-lg-block me-3" target="_blank">Help</a>

                <router-link to="/profile" class="ms-2 mt-n2 mb-n2" v-if="isLoggedIn && userInfo.profile_type == 'talent'">
                  <Avatar :avatarId="userInfo.avatar ?? '0'" size="small" class="dropdownToggleBtn avatar--light-outline" @click.prevent="toggleAvatarMenu()" />
                </router-link>

                <button class="btn btn-lg text-white p-2" @click.prevent="toggleAvatarMenu()" v-if="isLoggedIn && userInfo.profile_type == 'company'">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu w-auto dropdownToggleBtn"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
                </button>

                <Dropdown v-if="showAvatarMenu">
                  <div class="d-flex flex-column">
                    <a href="https://api.whatsapp.com/send/?phone=27795820675" target="_blank" class="drop-link">
                      <span class="icon material-symbols-outlined">
                        chat
                      </span>
                      Support Chat
                    </a>
                    <a href="mailto:feedback@secretsource.co.za" class="drop-link">
                      <span class="icon material-symbols-outlined">
                        email
                      </span>
                      Send Feedback
                    </a>

                    <a href="#" class="drop-link" @click.prevent="logout()" v-if="isLoggedIn">
                      <span class="icon material-symbols-outlined">
                        logout
                      </span> Logout
                    </a>

                    <!-- <router-link to="/logout" class="drop-link">
                      <span class="icon material-symbols-outlined">
                        logout
                      </span> Logout
                    </router-link> -->
                  </div>
                </Dropdown>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<style lang="scss" scoped>
@import "@/sass/_vuestrap.scss";

.notification-bell {
  padding:13px;
  position: relative;
  cursor: pointer;
  color: #CCC;
  border-radius: 50%;
  position: relative;
  &.active {
    &:after {
      content: '';
      position: absolute;
      top: 13px;
      right: 17px;
      width: 10px;
      height: 10px;
      background-color: yellow;
      border-radius: 50%;
    }
  }
  &:hover {
    background-color: #404040;
    color: #eee;
  }
  svg {
    width: 35px;
    height: 35px;
  }

}
.bataTag {
  background-color: #000;
  padding: 2px 5px;
  font-size: 10px;
  line-height: 1;
  color: #fff;
  position: absolute;
  bottom: -6px;
  right: -18px;
}
.headerWrapper {
  background-color: #4A4A4A;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}
.headerSpacer {
  height: 83px;
  background-color: #fff;
}
.logo {
 width: 51px;
//  margin-bottom: -60px;
}

.nav-link {
  font-weight: 600;
  font-size: 15px;
  color: #ddd;
  span {
    position: relative;
    display:block;
    padding: 3px 15px;
    border-radius: 6px;
  }
  &:hover span {
    background-color: #404040;
  }
  &.router-link-active span {
    background-color: #666;
    color: #fff;
  }
  &.active span {
    &:after {
      content: '';
      position: absolute;
      top: 2px;
      right: 2px;
      width: 7px;
      height: 7px;
      background-color: yellow;
      border-radius: 50%;
    }
  }
}
</style>