<script setup lang="ts">
import { ref, computed } from 'vue'
import { useTalentStore } from '@/stores/talentStore';
import { RouterLink } from 'vue-router';

const talentStore = useTalentStore()
const fields = computed(() => talentStore.getFields)
const searchQuery = ref('')

const props = defineProps({
  location: {
    type: String,
    default: 'banner'
  }
})

const filteredFields = computed(() => {
  if (!searchQuery.value) return fields.value
  
  return fields.value.map(field => ({
    ...field,
    roles: talentStore.getRolesByField(field.slug).filter(role =>
      role.name.toLowerCase().includes(searchQuery.value.toLowerCase())
      // role.name.toLowerCase().startsWith(searchQuery.value.toLowerCase())
    )
  })).filter(field => field.roles.length > 0)
})
</script>

<template>
  <div class="banner-search" :class="{'banner-search-banner': props.location === 'banner', 'banner-search-nav': props.location === 'nav'}">
    <div class="banner-search_input" :class="{'banner-search_input-nav': props.location === 'nav'}">
      <input
        v-model="searchQuery"
        type="text"
        placeholder="Search for a role ..."
        class="w-100"
      />
    </div>
    <div class="banner-search_roles" :class="{'banner-search_roles-banner': props.location === 'banner', 'banner-search_roles-nav': props.location === 'nav'}" v-if="searchQuery">
      <div v-if="filteredFields.length > 0" class="banner-search_role">
        <ul class="list-unstyled mb-0">
          <li v-for="field in filteredFields" :key="field.slug" class="mt-3">
            <div class="bg-lighter px-3 py-2">
              <router-link :to="`/search/${field.slug}`">
                <b>{{ field.name }}</b>
              </router-link>
            </div>
            <ul class="list-unstyled d-flex flex-column align-items-start gap-2 py-3 ms-3">
              <div v-for="role in field.roles" :key="role.slug" class="mx-3 mt-2 fw-500 fs-5 mb-0">
                <router-link :to="`/search/${field.slug}/${role.slug}`" class="d-flex align-items-center gap-2" @click="searchQuery = ''">
                  <div class="result-link">
                    {{ role.name }} <span class="p">({{ role.user_count }})</span>
                  </div>
                  <span class="icon material-symbols-outlined">chevron_right</span>
                </router-link>
              </div>
            </ul>
          </li>
        </ul>
      </div>
      <div v-else class="banner-search_no-results">
        <p class="mb-0 mt-3 fs-5">No role found for "{{ searchQuery }}"</p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/sass/vuestrap";
.icon {
  font-size: 1.5rem;
}
.result-link {
  &:hover {
    background-color: #FFFF00;
  }
}
.bg-lighter {
  background-color: $gray-200;
}
.banner-search {
  &-nav {
    width: 100%;
    max-width: 450px;
    margin-right: 50px;
    @media (min-width: 768px) {
      position: relative;
    }
  }
  &-banner {
    margin-bottom:20px;
    top: 50%;
    left: 50%;  
    right: 0;
    max-width: 800px;
    @media (min-width: 768px) {
      position: absolute;
      transform: translate(-50%, -50%);
      width:90%;
    }
  }
  .banner-search_input {
    width:100%;
    input {
      width:100%;
      border-radius: 10px;
      border: 1px solid $gray-300;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      padding: 10px 12px 10px 40px;
      background-color: rgba(255, 255, 255, 1);
      font-size: 1.3rem;
      background-repeat: no-repeat;
      background-position: 10px center;
      background-size: 20px;
    }
    &-nav {
      input {
        box-shadow: none;
        border:none;
        border-radius: 5px;
        padding: 10px 15px 10px 15px;
        background-color: $gray-800;
        margin-bottom: 0;
        margin-left: 10px;
        color: #ffff00;
        &::placeholder {
          color: #ccc;
        }
        &:focus {
          outline: none;
          &::placeholder {
            color: #888;
          }
        }
      }
    }
  }
}
.banner-search_roles {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid $gray-300;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding:0 15px 15px;
  max-height: 500px;
  overflow-y: auto;
  &-nav {
    width: 90%;
    margin: 0 5%;
    @media (min-width: 768px) {
      width: 100%;
    }
  }

}
.banner-search_no-results {
  text-align: center;
  padding: 20px;
  color: $gray-600;
  font-style: italic;
}
</style>
