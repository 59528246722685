<script setup lang="ts">
import { ref, watch } from 'vue'

import TextInput from '@/components/form/TextInput.vue';
import PasswordInput from '@/components/form/PasswordInput.vue'
import Button from '@/components/form/Button.vue';

import { triggerEvent } from '@/helpers/general'
import { useVuelidate } from '@vuelidate/core'
import { minLength, email, required, helpers, sameAs } from '@vuelidate/validators'

import { useUserStore } from '@/stores/userStore.ts'

const emit = defineEmits(['closeModal'])

const loading = ref<boolean>(false)
const error = ref<string>('')

const emailAddress = ref<string>('');
const password = ref<string>('');
const userType = ref<'company' | 'talent'>('company');
const userField = ref<string>('');
const termsChecked = ref<boolean>(false)
const isTalent = ref<boolean>(false)

const v$ = useVuelidate({
  emailAddress: {
    required: helpers.withMessage('Email is required', required),
    email: helpers.withMessage('Valid email address required.', email)
  },
  password: {
    required: helpers.withMessage('Password is required.', required),
    minLength: helpers.withMessage('Password needs to be atleast 6 characters.', minLength(8)),
  },
  userType: {
    required: helpers.withMessage('User type is required.', required),
  },
  userField: {
    required: helpers.withMessage('Your field of expertise is required.', (value) => 
      userType.value === 'talent' ? value !== '' : true
    ),
  },
  termsChecked: {
    required: helpers.withMessage('You need to accept the terms of service and privacy policy.', (value) => userType.value === 'talent' ? !!value : true),
    sameAs: helpers.withMessage('You need to accept the terms of service and privacy policy.', sameAs(true))
  }
}, { emailAddress, password, userType, userField, termsChecked, isTalent }, { $lazy: true });

// Update userType when isTalent changes
watch(isTalent, (newValue) => {
  userType.value = newValue ? 'talent' : 'company'
})

const login = async () => {
  v$.value.$touch();
  if (v$.value.$invalid) return;

  console.log('termsChecked', termsChecked.value)

    loading.value = true;
  try {
    await useUserStore().registerUser(emailAddress.value, password.value, userType.value, userField.value)

    loading.value = false;
    emit('closeModal')

    //reload the window
    // window.location.reload()

  } catch (requestError) {
    loading.value = false;
    error.value = requestError
  }
}


const openLoginModal = () => {
  triggerEvent('openAuthModal', {
    modalToShow: 'login'
  })
}

</script>

<template>
  <div>


    <div class="mb-3">
      <h4 class="h3">Register</h4>
    </div>

    <form @submit.prevent="login" class="d-flex flex-column gap-3">
      <TextInput
        id="email"
        label="Email Address"
        placeholder="you@cooldomain.com"
        v-model="v$.emailAddress.$model"
        :error="(v$.emailAddress.$dirty && v$.emailAddress.$invalid) ? v$.emailAddress.$errors[0].$message : ''" />


      <PasswordInput
        id="password"
        label="Password"
        placeholder="Password"
        v-model="v$.password.$model"
        :isInvalid="(v$.password.$dirty && v$.password.$invalid)"
        :error="(v$.password.$dirty && v$.password.$invalid) ? v$.password.$errors[0].$message : ''" />
      
      <p v-if="error" class="text-danger small">{{ error }}</p>

      <div class="checkbox-container d-flex gap-3 align-items-center mt-2">
        <input type="checkbox" id="isTalent" v-model="isTalent" class="form-check-input">
        <label for="isTalent" class="form-check-label mt-1">
          I want to create a talent profile to offer my services
        </label>
      </div>
      
      <div class="select-field" v-if="isTalent">
        <select class="w-100 form-select" placeholder="Select your field" id="fieldInput" v-model="v$.userField.$model" @change="v$.userField.$touch()">
          <option value="" disabled>Select your field</option>
          <option value="design">Design</option>
          <option value="advertising">Advertising &amp; Marketing</option>
          <option value="production">Production</option>
          <option value="engineering">Engineering</option>
          <option value="people-managers">People Managers</option>
        </select>

        <p v-if="v$.userField.$dirty && v$.userField.$invalid && userType === 'talent'" class="error mb-0 text-danger small mt-3">
          {{ v$.userField.$errors[0].$message }}
        </p>
      </div>
      
      <div class="terms d-flex gap-2 align-items-start mt-3">
        <input type="checkbox" id="terms" 
          v-model="v$.termsChecked.$model"
          class="form-check-input flex-shrink-0" />
        <label for="terms" class="form-check-label smaller ms-2 my-1">
          I have read and accept the <a href="/terms-of-use" target="terms" class="text-underline">Terms&nbsp;of&nbsp;Service</a> and <a href="/privacy-policy" target="terms" class="text-underline">Privacy&nbsp;Policy</a>
        </label>
      </div>
      <p v-if="v$.termsChecked.$dirty && v$.termsChecked.$invalid" class="error mb-0 text-danger small mt-0">{{ v$.termsChecked.$errors[0].$message }}</p>

      <div class="d-flex justify-content-between align-items-end mt-2">
        <Button 
          class="btn-dark w-100" 
          :disabled="loading" 
          :loading="loading">Register as {{ v$.userType.$model == 'company' ? 'a Company' : 'Talent' }}</Button>
      </div>
      <hr>
      <div class="text-center">
        <a href="#" @click.prevent="openLoginModal()">
         Already have an account? <b>Click here to Login</b>
        </a>
      </div>
    </form>

  </div>

</template>

<style scoped>
.terms {
  background-color: #efefeb;
  border-radius: 8px;
  padding: 10px;
}
.text-underline {
  text-decoration: underline;
}
</style>
