<script setup lang="ts">
import { ref, reactive, watch, computed, onMounted, nextTick } from 'vue'

import StepLayout from '@/components/wizard/Components/StepLayout.vue'
import SelectInput from '@/components/form/SelectInput.vue'
import StepFooter from '@/components/wizard/Components/StepFooter.vue'
import SubTab from '@/components/wizard/Components/SubTab.vue'

import RoleExperience from '@/components/wizard/Modals/RoleExperience.vue'

import cookies from '@/helpers/cookies'

import { useUserStore } from '@/stores/userStore.ts'
import { useWizardStore } from '@/stores/wizardStore.ts'
import { useRoleStore } from '@/stores/roleStore.ts'

const emit = defineEmits(['removeComponent', 'addComponent'])

const pageMounted = ref<boolean>(false);
const newRole = ref<boolean>(false);
const newRoleLabel = ref<string>('')

const editMode = computed(() => useWizardStore().currentEditSteps.length > 0)

const headline = computed(() => {
  if (editMode.value) {
    return 'Update your roles and experience'
  } else {
    return 'Add your primary role and any others that apply to you.'
  }
})

const buttonLabel = computed(() => {
  if (editMode.value) {
    return 'Update'
  } else {
    return 'Continue'
  }
})

const userPrimaryDetails = useUserStore().userComputed.primary_details
const userSecondaryDetails = useUserStore().userComputed.secondary_details
const userOtherDetails = useUserStore().userComputed.other_details

const field = useUserStore().getField()
const roleOptions = useRoleStore().getFieldRoles(useUserStore().userComputed.field).roles
const newRoles = ref<Array<object>>([])

const user = useUserStore().userComputed

const modalToShow = ref<string|null>(null);
const loading = ref<boolean>(false);
const error = ref<string|null>(null);

const primaryError = ref<string|null>(null);
const primaryRoleSelect = ref<string>('');
const primaryRole = ref<object>({
  roleId: '',
  roleName: '',
  year: '',
  level: '',
});

const otherRolesSelected = ref<Array<object>>([]);

if (userOtherDetails) {
  userOtherDetails.forEach(role => {
    otherRolesSelected.value.push(role)
  })
}

const toggleRole = (role) => {
  const index = otherRolesSelected.value.findIndex((selectedRoles) => selectedRoles.value == role.value)

  if (index > -1) {
    otherRolesSelected.value.splice(index, 1)
  } else {
    otherRolesSelected.value.push(role)
  }
}

const hasRole = (role) => {
  return otherRolesSelected.value.findIndex((selectedRoles) => selectedRoles.value == role.value) > -1
}
  
if (userPrimaryDetails) {
  primaryRole.value = userPrimaryDetails
  primaryRoleSelect.value = primaryRole.value.roleId
  newRoles.value.push({ value: primaryRole.value.roleId, label: primaryRole.value.roleName })
}

const primaryValid = computed(() => {
  return primaryRole.value.roleId && primaryRole.value.year && primaryRole.value.level
})

const secondaryError = ref<string|null>(null);
const secondaryRoleSelect = ref<string>('');
const secondaryRole = ref<object>({
  roleId: '',
  roleName: '',
  year: '',
  level: '',
});

if (userSecondaryDetails) {
  secondaryRole.value = userSecondaryDetails
  secondaryRoleSelect.value = secondaryRole.value.roleId
  newRoles.value.push({ value: secondaryRole.value.roleId, label: secondaryRole.value.roleName })
}

const secondaryValid = computed(() => {
  return secondaryRole.value.roleId && secondaryRole.value.year && secondaryRole.value.level
})


const closeModal = (modalData) => {
  if (modalData && modalData.startYear && modalData.currentLevel) {
    if (modalToShow.value === 'primary') {
      primaryRole.value.year = modalData.startYear
      primaryRole.value.level = modalData.currentLevel

    } else if (modalToShow.value === 'secondary') {
      secondaryRole.value.year = modalData.startYear
      secondaryRole.value.level = modalData.currentLevel
    }

    if (modalData.roleName) {
      if (modalToShow.value === 'primary') {
        primaryRole.value.roleName = modalData.roleName
        primaryRole.value.roleId = modalData.roleName.toLowerCase().replace(/\s/g, '-')
        primaryRoleSelect.value = primaryRole.value.roleId
        newRoles.value.push({ value: primaryRole.value.roleId, label: primaryRole.value.roleName })
        // document.cookie = 'selected_role=zip; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
      } else if (modalToShow.value === 'secondary') {
        secondaryRole.value.roleName = modalData.roleName
        secondaryRole.value.roleId = modalData.roleName.toLowerCase().replace(/\s/g, '-')
        secondaryRoleSelect.value = secondaryRole.value.roleId
        newRoles.value.push({ value: secondaryRole.value.roleId, label: secondaryRole.value.roleName })
        // document.cookie = 'selected_role=zip; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
      }

      cookies.deleteCookie('selected_role')
    }
  } else {
    if (modalToShow.value === 'primary') {
      clearData('primary')
    } else if (modalToShow.value === 'secondary') {
      clearData('secondary')
    }
  }
  modalToShow.value = null
}

const clearData = (modalData) => {
  if (modalData === 'primary') {
    primaryRoleSelect.value = ''
    primaryRole.value.roleId = ''
    primaryRole.value.roleName = ''
    primaryRole.value.year = ''
    primaryRole.value.level = ''
  } else if (modalData === 'secondary') {
    secondaryRoleSelect.value = ''
    secondaryRole.value.roleId = ''
    secondaryRole.value.roleName = ''
    secondaryRole.value.year = ''
    secondaryRole.value.level = ''
  }
}

const mergedRoles = computed(() => {
  //push 'Other' to the end of the array
  let combined = [...roleOptions, ...newRoles.value].filter((item, index, self) =>
    index === self.findIndex((t) => (
      t.value === item.value
    ))
  )
  combined.sort((a, b) => a.label.localeCompare(b.label))
  combined.push({ value: 'new-role', label: 'Other ...' })
  return combined
})

const submitForm = async () => {
  let isValid = true
  if (!primaryValid.value) {
    primaryError.value = 'Please select an option'
    isValid = false
  }

  let secondaryData = null
  if (!secondaryValid.value) {
    useWizardStore().removeStep('rates-secondary-role')
    emit('removeComponent', 'rates-secondary-role')
  } else {
    secondaryData = JSON.stringify(secondaryRole.value)
    useWizardStore().addStep('rates-secondary-role', 6)
    emit('addComponent', 'rates-secondary-role', 6)
  }
  if (!isValid) 
    return;

  
  error.value = null
  loading.value = true;
  try {
    await useUserStore().updateUser({
      primary_details: JSON.stringify(primaryRole.value),
      secondary_details: secondaryData,
      other_details: JSON.stringify(otherRolesSelected.value)
    })

    cookies.deleteCookie('selected_role')
    loading.value = false
    useWizardStore().markCurrentStepComplete()
  } catch (e) {
    error.value = e
  }
}

onMounted(() => {
  if (primaryValid.value) {
    primaryRoleSelect.value = primaryRole.value.roleId
  }

  nextTick(() => {
    pageMounted.value = true
  })


  const roleCookie = cookies.getCookie('selected_role')
  if (roleCookie && !['', 'zip', null, undefined].includes(roleCookie)) {
    const roleOption = roleOptions.find(option => option.label === newRoleLabel.value)
    if (!roleOption && primaryRoleSelect.value == '') {
      newRole.value = true
      modalToShow.value = 'primary'
    }
  }
})

const setRoleName = (roleId, type) => {
  const selectedOption = roleOptions.find(option => option.value === roleId)
  if (selectedOption) {
    if (type === 'primary') {
      primaryRole.value.roleName = selectedOption.label
    } else if (type === 'secondary') {
      secondaryRole.value.roleName = selectedOption.label
    }
  }
}

watch(primaryRoleSelect, (newValue) => {
  if (pageMounted.value && newValue) {
    primaryError.value = null
    primaryRole.value.roleId = newValue
    if (newValue === 'new-role') {
      newRole.value = true
      modalToShow.value = 'primary'
    } else {
      if (!newRole.value) {
        modalToShow.value = 'primary'
      }
      newRole.value = false
      setRoleName(newValue, 'primary')
    }
  }
}, { deep: true })

watch(secondaryRoleSelect, (newValue) => {
  if (pageMounted.value && newValue) {
    secondaryError.value = null
    secondaryRole.value.roleId = newValue
    if (newValue === 'new-role') {
      newRole.value = true
      modalToShow.value = 'secondary'
    } else {
      if (!newRole.value) {
        modalToShow.value = 'secondary'
      }
      newRole.value = false
      setRoleName(newValue, 'secondary')
    }
  }
}, { deep: true })
</script>

<template>
<StepLayout 
  maxWidth="730px"
  :headline="headline">
  
  <RoleExperience 
    v-if="modalToShow" 
    :newRole="newRole"
    :roleLabel="newRoleLabel"
    :modalData="modalToShow"
    @closeModal="closeModal" />
  
  <form @submit.prevent="submitForm">
    <div class="mx-auto" style="max-width: 450px;">
      <SelectInput
        id="primaryRole"
        label="Primary Role"
        v-model="primaryRoleSelect"
        inlineLabel
        :optionsArray="mergedRoles"
        :error="primaryError" />
        
        <SubTab v-if="primaryRole.year" @clear="clearData('primary')" class="justify-content-start justify-content-sm-end">
          <div class="sub-tab" @click="modalToShow = 'primary'"> {{ primaryRole.year }}</div>
          <div class="sub-tab" @click="modalToShow = 'primary'"> {{ primaryRole.level }}</div>
        </SubTab>
    </div>

    <div class="py-3"></div>

    <div class="mx-auto" style="max-width: 450px;" :class="{'disabled-step': !primaryRoleSelect}">
      <SelectInput
        id="secondaryRole"
        :optional="!secondaryRole.year"
        label="Secondary Role"
        v-model="secondaryRoleSelect"
        inlineLabel
        :optionsArray="mergedRoles"
        :error="secondaryError" />

        <SubTab v-if="secondaryRole.year" @clear="clearData('secondary')" class="justify-content-start justify-content-sm-end">
          <div class="sub-tab" @click="modalToShow = 'secondary'"> {{ secondaryRole.year }}</div>
          <div class="sub-tab" @click="modalToShow = 'secondary'"> {{ secondaryRole.level }}</div>
        </SubTab>
        
    </div>
    <br>
    <div :class="{'disabled-step': !primaryRoleSelect}">
      <p>Other roles</p>
      <ul class="list-unstyled d-flex gap-1 gap-md-2 flex-wrap select-list">
        <li v-for="(role, index) in mergedRoles" :key="index"
          @click="toggleRole(role)"
          :class="{'selected': hasRole(role), 'd-none': role.value === 'new-role'}"
        >
          <span>{{ role.label }}</span>
        </li>
      </ul>
    </div>
    <StepFooter :loading="loading" :label="buttonLabel" />
  </form>
</StepLayout>
</template>